











import {Component, Vue, Prop} from 'vue-property-decorator';

@Component({
  name: 'search-result-suggested-categories',
})

export default class extends Vue {
  @Prop() private resultData: Array<object>;
}

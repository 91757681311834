

























































import {Component, Vue, Prop} from 'vue-property-decorator';
import {getDirective} from 'vue-debounce';

import SearchResultSuggestedCategories from './results/SearchResultSuggestedCategories.vue';
import SearchResultSuggestedProducts from './results/SearchResultSuggestedProducts.vue';
import SearchResultSuggestedKeywords from './results/SearchResultSuggestedKeywords.vue';

@Component({
  name: 'search-form',
  components: {
    SearchResultSuggestedCategories,
    SearchResultSuggestedProducts,
    SearchResultSuggestedKeywords
  },
  directives: {
    debounce: getDirective(),
    'click-outside': {
      bind(el, binding, vnode) {
        el.clickOutsideEvent = (event) => {
          if (!(el === event.target || el.contains(event.target))) {
            vnode.context[binding.expression](event);
          }
        };
        document.body.addEventListener('click', el['clickOutsideEvent']);
      },
      unbind(el) {
        document.body.removeEventListener('click', el['clickOutsideEvent']);
      }
    }
  }
})

export default class extends Vue {
  @Prop() private initialQuery: string;

  private query: string = '';
  private keydownTimeout: any = null;
  private autocompleteLoading: boolean = false;
  private isSearchVisible: boolean = false;
  private searchResults: object = {
    suggested_keywords: [],
    suggested_categories: [],
    suggested_products: []
  };

  private get isSearchEmpty(): boolean {
    return this.searchResults['suggested_categories'].length === 0
      && this.searchResults['suggested_products'].length === 0
      && this.searchResults['suggested_keywords'].length === 0;
  }

  private handleKeyUp(event): void {
    //use event to fix for android devices
    this.query = event.target.value;

    if (this.searchResults['suggested_products'].length === 0
      && this.searchResults['suggested_categories'].length === 0
      && this.searchResults['suggested_keywords'].length === 0
    ) {
      this.autocompleteLoading = true;
    }

    clearTimeout(this.keydownTimeout);
    this.keydownTimeout = setTimeout(() => {
      this.executeAutocomplete();
    }, 250)
  }

  private async executeAutocomplete(): Promise<void> {
    if (this.query.length < 3) {
      this.autocompleteLoading = false;
      this.isSearchVisible = false;
      if (this.$mobileDetect.mobile()) {
        document.body.classList.remove('no-scroll');
      }
      return;
    }

    try {
      const {data} = await this.$solarClient.get(`api/search/autocomplete/?q=${this.query}`);

      this.searchResults['suggested_products'] = data.product_results;
      this.searchResults['suggested_categories'] = data.category_results;
      this.searchResults['suggested_keywords'] = data.keyword_results;

      this.autocompleteLoading = false;
      this.isSearchVisible = true;
    } catch (e) {
    }

    if (this.$mobileDetect.mobile()) {
      document.body.classList.add('no-scroll');
    }
  }


  private closeSearchHolder(): void {
    this.isSearchVisible = false;
    this.autocompleteLoading = false;
    this.query = '';
    this.searchResults = {
      suggested_categories: [],
      suggested_keywords: [],
      suggested_products: [],
    };
    if (this.$mobileDetect.mobile()) {
      document.body.classList.remove('no-scroll');
    }
  }

  mounted(): void {
    if (this.$mobileDetect.mobile()) {
      window.addEventListener('click', (e) => {
        if ((e.target as HTMLElement).classList.contains('search__autocomplete__dropdown')) {
          this.closeSearchHolder();
        }
      });
    }
  }

  private created(): void {
    this.query = this.initialQuery;
  }
}






















import {Component, Vue, Prop} from 'vue-property-decorator';

@Component({
  name: 'search-result-suggested-products',
})

export default class extends Vue {
  @Prop() private resultData: Array<object>;
  @Prop() private searchQuery: string;

  private productImage(product: object, size: number): string {
    if (null !== product['image']) {
      return product['image'].placeholder_url.replace('{size}', size);
    }
    return '';
  }

  private get firstCategory(): Object {
    return this.resultData[0]['category'][0][0];
  }

  private getUrl(product: object): string {
    if (this.searchQuery.length > 5 && /^\d+$/.test(this.searchQuery)) {
      return product['url'];
    }

    return `${product['url']}?q=${this.searchQuery}`;
  }
}

const getters = {
  getProductImpressions: (state, getters, rootGetters) => {
    const _cartTotals = rootGetters['CheckoutTotals']['cartTotals'];
    let _productImpressions = [];

    try {
      _cartTotals.items.forEach(function (item) {
        let categoryNames = '';
        if (item['extension_attributes']['category_names']) {
          const categories = JSON.parse(item['extension_attributes']['category_names']);
          categoryNames = categories.join(' | ').trim();
        }

        _productImpressions.push({
          'name': item['name'],
          'id': item['extension_attributes']['sku'],
          'price': item['row_total_incl_tax'],
          'sku': item['extension_attributes']['sku'],
          'ean': item['extension_attributes']['ean'] || '',
          'brand': item['extension_attributes']['brand'] || 'Xenos',
          'category': categoryNames,
          'variant': item['extension_attributes']['variant'] || '',
          'delivery-time': item['extension_attributes']['delivery_time'],
          'dimension20': item['extension_attributes']['gses_durability_score'] > 0 ? item['extension_attributes']['gses_durability_score'] : null,
          'quantity': item['qty']
        });
      });
    } catch (err) {

    }

    return _productImpressions;
  },
};

const actions = {
  dataLayerPushCheckout({state, commit, getters, dispatch}, payload) {
    window.dataLayer.push({
      'event': 'checkout',
      'ecommerce': {
        'checkout': {
          'actionField': payload,
          'products': getters['getProductImpressions'],
        }
      }
    });
  },
}

const CheckoutDatalayer = {
  namespaced: true,
  getters,
  actions,
}

export default CheckoutDatalayer;

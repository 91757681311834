export default {
    "Stores": "Winkels",
    "Account": "Account",
    "Favorites": "Favorieten",
    "Cart": "Winkelwagen",
    "Back": "Terug",
    "{review} reviews": "{1} 1 review|[2,*] {review} reviews",
    "Submit review": "Plaats een review",
    "Written by {author}": "Geschreven door {author}",
    "Yes, I recommend this product.": "Ja, ik beveel dit product aan.",
    "No reviews found": "Geen beoordelingen gevonden",
    "Show more": "Toon meer",
    "Do you own the {name}? Write a review!": "Heb jij {name}? Schrijf een review!",
    "Do you also own the {name}? Write a review!": "Heb jij {name}? Schrijf ook een review!",
    "Give your opinion": "Geef je mening",
    "You are visiting our webshop from {country}": "Je bezoekt onze webshop vanuit {country}",
    "Go to {store}": "Ga naar {store}",
    "Netherlands": "Nederland",
    "Belgium": "Belgi\u00eb",
    "Germany": "Duitsland",
    "What are you looking for?": "Waar ben je naar op zoek?",
    "Add to cart": "In mijn winkelmandje",
    "Checkout": "Bestellen",
    "Shopping cart": "Winkelmandje",
    "Description": "Omschrijving",
    "Quantity": "Aantal",
    "Unit price": "Stukprijs",
    "Subtotal": "Subtotaal",
    "Sku:": "Artikelnummer:",
    "Maximum of {qty} per customer.": "Maximaal {qty} stuks per klant.",
    "Presale item": "Voorverkoop artikel",
    "Free": "Gratis",
    "Continue shopping": "Verder winkelen",
    "To checkout": "Naar bestellen",
    "This is a secure connection": "Dit is een veilige verbinding",
    "You can order online from {min}OrderValue. Order for {remaining}OrderValue to complete your order. <a href='/'>Click here to continue shopping</a>": "Je kunt online bestellen vanaf {min}OrderValue. Bestel nog voor {remaining}OrderValue om je bestelling af te kunnen ronden. <a href='#'>Klik hier om verder te winkelen</a>",
    "Minimum order value": "Minimale bestelwaarde",
    "There are no available shipping methods": "Op het opgegeven bezorgadres is geen levering mogelijk van Extra@Home artikelen. Vul een ander bezorgadres in <a href=\"/checkout/cart\">of ga terug naar je winkelmandje.</a>",
    "or visit one of our 150 stores.": "of ga naar een van onze 150 winkels.",
    "<strong>Free</strong> delivered from {shipping}Cutoff or choose in the next step for free collection at a Xenos store.": "<strong>Gratis</strong> bezorgd vanaf {shipping}Cutoff of kies in de volgende stap voor gratis afhalen bij een Xenos winkel.",
    "<strong>Free</strong> delivered from {shipping}Cutoff.": "<strong>Gratis</strong> bezorgd vanaf {shipping}Cutoff.",
    "Your order will be delivered <strong>free</strong> at home.": "Je bestelling wordt <strong>gratis</strong> thuisbezorgd.",
    "By continuing to order, you agree to our <a href='{url}'>Terms & Conditions</a>.": "Door verder te gaan met bestellen, ga je akkoord met onze <a href='{url}'>Algemene voorwaarden</a>.",
    "Customer login": "Klanten login",
    "Existing customers": "Geregistreerde klanten",
    "If you have an account, sign in with your email address.": "Als je een account hebt, meld je dan aan met je e-mailadres.",
    "Email address": "E-mail",
    "Password": "Wachtwoord",
    "Login": "Inloggen",
    "Forgot password?": "Wachtwoord vergeten?",
    "Your password has been updated": "Je wachtwoord is gewijzigd",
    "Successfully saved address": "Het adres is opgeslagen",
    "New customers": "Nieuwe klanten",
    "With an account you have your orders in one place, all your address details are stored and you can more easily evaluate products.": "Met een account heb je je bestellingen op \u00e9\u00e9n plek, zijn al je adresgegevens opgeslagen en kun je eenvoudiger producten beoordelen.",
    "Create account": "Account aanmaken",
    "Create a new account": "Registreer een nieuw account",
    "Personal information": "Persoonlijke informatie",
    "Prefix": "Aanhef",
    "First name": "Voornaam",
    "Last name": "Achternaam",
    "Company": "Bedrijfsnaam",
    "VAT number": "BTW nummer",
    "CoC number": "Ondernemingsnummer",
    "Confirm password": "Bevestig wachtwoord",
    "My account": "Mijn account",
    "Hello {name}!": "Hallo {name}!",
    "This personal page offers you an overview of the most recent activities. You can, among other things, view orders and update account information. Click on a link below to get started!": "Je persoonlijke pagina biedt je een overzicht van je meest recente activiteiten. Je kunt o.a. bestellingen inzien en accountgegevens bijwerken.",
    "Account Details": "Account Gegevens",
    "Edit": "Bewerken",
    "Newsletter": "Nieuwsbrief",
    "Always informed about the new collection and promotions. Sign up and receive a 10% discount on your next purchase!": "Altijd op de hoogte van de nieuwe collectie en acties. Meld je aan en ontvang 10% korting op je volgende aankoop!",
    "Subscribe": "Aanmelden",
    "My address book": "Mijn adresboek",
    "Set a billing and/or shipping address for your account, then ordering on Xenos.nl will be even easier.": "Stel een factuur- en/of verzendadres in voor jouw account, dan wordt bestellen op Xenos.nl nog eenvoudiger.",
    "My orders": "Mijn bestellingen",
    "Here you will find an overview of your webshop orders on Xenos.nl and the details per order.": "Hier vind je het overzicht van jouw webshopbestellingen op Xenos.nl en de details per order.",
    "Order history": "Orderhistorie",
    "Edit personal settings": "Bewerk accountgegevens",
    "Change your personal details below": "Wijzig hieronder je persoonlijke gegevens",
    "Enter your new password": "Vul je nieuwe wachtwoord in",
    "Current password": "Huidig wachtwoord",
    "New password": "Nieuw wachtwoord",
    "Confirm new password": "Bevestig nieuw wachtwoord",
    "Save": "Opslaan",
    "Change password": "Wachtwoord wijzigen",
    "Receive the general newsletter": "Ontvang de algemene nieuwsbrief",
    "What do we do with your data?": "Wat doen we met je gegevens?",
    "Hey {name},": "H\u00e9 {name},",
    "Addresses": "Adresboek",
    "Default shipping address": "Standaard verzendadres",
    "No default shipping address available": "Geen standaard verzendadres ingesteld",
    "No default billing address available": "Geen standaard factuuradres ingesteld",
    "Default billing address": "Standaard factuuradres",
    "Remove address": "Verwijder adres",
    "Extra Addresses": "Extra adressen",
    "No addresses available": "Geen adressen beschikbaar",
    "Add new address": "Adres toevoegen",
    "Contact details": "Contactgegevens",
    "Phone number": "Telefoonnummer",
    "Address": "Adres",
    "Postal code": "Postcode",
    "House no.": "Huisnummer",
    "Street": "Straat",
    "City": "Plaats",
    "Select a country": "Kies je land",
    "Save address": "Adres opslaan",
    "Individual": "Particulier",
    "Business": "Zakelijk",
    "My favorites": "Mijn favorieten",
    "Edit address": "Bewerk adres",
    "Account overview": "Accountoverzicht",
    "Personal settings": "Account gegevens",
    "Logout": "Uitloggen",
    "You are now signed out": "Je bent nu uitgelogd",
    "You are now logged out and will return to our home page within 5 seconds.": "Je bent nu uitgelogd en zal over 5 seconden automatisch naar de homepage doorgestuurd worden.",
    "Your personal email address": "Je persoonlijke e-mailadres",
    "Enter your email address below to receive a password reset link.": "Voer je e-mailadres hieronder in om een wachtwoord reset link te ontvangen.",
    "Request password": "Wachtwoord resetten",
    "successfully logged out": "Je bent succesvol uitgelogd",
    "Your account has been created successfully.": "Je account is succesvol aangemaakt",
    "Customer sign in": "Klanten login",
    "Register a new account": "Registreer een nieuw account",
    "Forgot your password?": "Wachtwoord vergeten?",
    "Reset password": "Een nieuw wachtwoord instellen",
    "Password and password confirmation do not match": "Wachtwoord en wachtwoord bevestiging komen niet overeen",
    "Order overview": "Mijn bestellingen",
    "Order details": "Mijn bestelling",
    "Manage addresses": "Adresboek",
    "Create new address": "Adres toevoegen",
    "Edit account details": "Account gegevens",
    "Newsletter subscriptions": "Nieuwsbrief",
    "My wishlist": "Mijn favorieten",
    "If an account with the given email address exists you will receive a password reset email.": "Als er een account is gekoppeld aan het opgegeven e-mailadres, ontvang je een e-mail met een link om je wachtwoord opnieuw in te stellen.",
    "Order no.": "Ordernr.",
    "Date": "Datum",
    "Ship to": "Verzenden aan",
    "NEW": "Nieuw",
    "EXPORTED": "Wordt verzameld",
    "EXPORT FAILED": "Wordt verzameld",
    "SHIPPED": "Verstuurd",
    "MIXED": "Deels retour",
    "PARTIALY SHIPPED": "Deels verstuurd",
    "COLLECTED": "Afgehaald",
    "RETURNED": "Geretourneerd",
    "CANCELLED": "Geannuleerd",
    "Shipping method": "Bezorgmethode",
    "Payment method": "Betaalmethode",
    "Order {increment}": "Bestelling {increment}",
    "Shipping costs": "Verzendkosten",
    "Payment Fee": "Transactiekosten",
    "Product discount": "Artikelkortingen",
    "Grand total": "Eindtotaal",
    "Tax": "Waarvan BTW",
    "Back to my orders": "Terug naar mijn bestellingen",
    "You have not placed any orders yet": "Je hebt nog geen orders geplaatst",
    "Search results for \"{term}\"": "Zoekresultaten voor \"{term}\"",
    "Newest inspiration:": "Nieuwste inspiratie:",
    "Posted on:": "Geplaatst op:",
    "Posted in:": "Geplaatst in:",
    "No posts found": "Geen berichten gevonden",
    "Discover it with {name}": "Ontdek het met {name}",
    "Blog categories": "Blog categorie\u00ebn",
    "Posted:": "Geplaatst:",
    "View more in:": "Bekijk meer in:",
    "Share this post:": "Deel dit bericht:",
    "Search posts..": "Bericht zoeken..",
    "Search for inspiration..": "Inspiratie zoeken..",
    "Get inspired?": "Meer inspiratie opdoen?",
    "Back to blog overview": "Terug naar blogoverzicht",
    "Go to {store}Name": "Ga naar {store}Name",
    "Contact": "Contact",
    "Opening hours": "Openingstijden",
    "Special opening hours": "Speciale openingstijden",
    "Closed on": "Gesloten op",
    "Closed": "Gesloten",
    "Monday": "Maandag",
    "Tuesday": "Dinsdag",
    "Wednesday": "Woensdag",
    "Thursday": "Donderdag",
    "Friday": "Vrijdag",
    "Saturday": "Zaterdag",
    "Sunday": "Zondag",
    "Today": "Vandaag",
    "Xenos stores near you": "Xenos winkels bij jou in de buurt",
    "Overview of all Xenos stores": "Overzicht van alle Xenos winkels",
    "Overview of all Xenos stores in {city}": "Overzicht van alle Xenos winkels in {city}",
    "Popular Cities": "Populaire steden",
    "Xenos stores in {city}": "Xenos winkels in {city}",
    "View all stores in {city} below. Our employees work hard to offer you the best products and service every day.": "Bekijk hieronder alle winkels in {city}. Onze medewerkers werken hard om elke dag een goed assortiment en goede service aan jou te bieden.",
    "Promotion": "Actie",
    "Sold out online, view store stock.": "Online uitverkocht, bekijk winkelvoorraad.",
    "Sold out online.": "Online uitverkocht.",
    "Sorry, this product is currently sold out.": "Sorry, dit product is momenteel uitverkocht.",
    "Sold out.": "Uitverkocht",
    "This product is only available in our <a href='{store}Url'>stores</a>.": "Dit product is alleen verkrijgbaar in onze <a href='{store}Url'>winkels</a>.",
    "This product will be online soon! Find a <a href='{store}Url'>Xenos store</a> near you here.": "Dit artikel verschijnt binnenkort online! Vind hier een <a href='{store}Url'>Xenos winkel</a> bij jou in de buurt.",
    "This product will be online soon!": "Dit artikel verschijnt binnenkort online!",
    "In stock": "Online beschikbaar",
    "Only {stock}Qty in stock remaining": "Online nog {stock}Qty stuks",
    "Plenty stock": "Ruim op voorraad",
    "Not saleable": "Dit product is (tijdelijk) niet verkoopbaar",
    "Specifications": "Specificaties",
    "Thank you for your purchase!": "Bedankt voor je bestelling!",
    "Your order has been successfully placed.": "Je bestelling is succesvol geplaatst.",
    "Your order number is: #{order}Id. You will receive an email confirming your order with all the details within 15 minutes. Also check your spam folder if you have not received a confirmation email after 15 minutes. If you have any questions about the status of your order, it is best to contact customer service.": "Je ordernummer is: #{order}Id. Je ontvangt binnen nu en een kwartier een e-mail ter bevestiging van je bestelling met alle details. Controleer ook je spamfolder als je na een kwartier nog geen bevestigingsmail hebt ontvangen. Mocht je vragen hebben over de status van je bestelling kun je hiervoor het beste contact opnemen met de klantenservice.",
    "Please note: You must first pay at the checkout, only then will your order with order number #{order}Id be definitively processed.": "Let op: Je dient eerst nog te betalen bij de kassa, pas dan wordt je bestelling met ordernummer #{order}Id definitief doorgezet.",
    "Billing address": "Factuuradres",
    "Shipping address": "Bezorgadres",
    "Share your purchase with friends and family:": "Deel je aankoop met vrienden en familie:",
    "Tadaaa! Look what I just bought on Xenos": "Tadaaa! Kijk wat ik net heb gekocht op Xenos",
    "No products found for this look": "Geen producten gevonden voor deze stijl",
    "No looks found in this category": "Geen stijlen gevonden in deze categorie",
    "Order these products and receive a discount of {discount}!": "Bestel deze producten en ontvang in totaal {discount} korting!",
    "{discount} discount": "{discount} korting",
    "Total": "Totaal",
    "View more styles": "Bekijk meer stijlen",
    "No active folders found": "Er zijn geen actieve folders gevonden",
    "Take a quick look at our new folder": "Bekijk snel onze nieuwe folder",
    "Product not in stock": "Artikel niet op voorraad",
    "One or more products are out of stock, please check you items and remove the out of stock product(s)": "\u00c9\u00e9n of meerdere artikelen zijn niet op voorraad, controleer je artikelen en verwijder de artikelen die niet op voorraad zijn.",
    "Pickup at Xenos store": "Ophalen bij een winkel in de buurt",
    "PostNL Pickup-point": "Selecteer een PostNL Pakketpunt",
    "Delivered with PostNL": "Bezorgd met PostNL",
    "Country": "Land",
    "-- Choose your country --": "-- Kies je land --",
    "Back to cart": "Terug naar winkelwagen",
    "To shipping": "naar bezorging",
    "House number": "Huisnummer",
    "Addition": "Toevoeging",
    "Add.": "Toev.",
    "Telephone": "Telefoon",
    "We have found the following address": "We hebben dit adres gevonden",
    "You have an account. Would you like to {action}?": "Je hebt al een account. Wil je {action}?",
    "Already have an account with us? Log in {here} and we will retrieve your details.": "Heb je al een account bij ons? Log {here} in en wij halen je gegevens erbij.",
    "Details": "Gegevens",
    "Shipping": "Bezorging",
    "Payment": "Betaling",
    "Shipping methods": "Bezorgmethodes",
    "Green choice": "Groene keuze",
    "Select a Xenos store": "Selecteer een Xenos winkel",
    "Xenos store": "Xenos winkel",
    "Address data": "Adresgegevens",
    "Payment methods": "Betaalmethode",
    "I have a discount code": "Kortingscode invoeren",
    "Apply": "Toepassen",
    "You can pick up your order at": "Je bestelling afhalen bij",
    "Your order will be delivered on": "Je bestelling wordt bezorgd op",
    "Overview of your order": "Overzicht van je bestelling",
    "Your details": "Je gegevens",
    "Change": "Wijzig",
    "Complete order": "Afrekenen",
    "To payment": "naar betaling",
    "Enter zip code or city..": "Vul postcode of plaats in..",
    "Enter zip code..": "Vul postcode in..",
    "Pickup point": "PostNL Afhaal punt",
    "Please wait, loading shipping methods": "Een ogenblik geduld, we zijn de bezorgmethodes aan het laden..",
    "Please wait, we are loading the stores..": "Een ogenblik geduld, we zijn de winkels aan het laden..",
    "Please wait, loading payment methods": "Een ogenblik geduld, we zijn de betaalmethoden aan het laden..",
    "Billing address is different from the address above": "Factuuradres is anders dan bovenstaand adres",
    "Your applied coupon code is {couponCodeInput}.": "Jouw toegepaste couponcode is {couponCodeInput}.",
    "Without extra fees": "Gratis",
    "Free, most chosen payment method in the Netherlands": "Gratis, meest gekozen betaalmethode in Nederland",
    "Pay afterwards": "Achteraf betalen",
    "Cancel": "Annuleren",
    "Choose your bank": "Kies je bank",
    "Your email": "Jouw e-mailadres",
    "Search results for": "Zoekresultaten voor",
    "Sign up for the newsletter": "Meld je aan voor de nieuwsbrief",
    "here": "hier",
    "Select the Xenos store of your choice": "Selecteer een Xenos winkel naar keuze",
    "Shipping & Handling": "Verzendkosten",
    "Add to my favorites": "Voeg toe aan mijn favorieten",
    "View the stock in store": "Bekijk winkelvoorraad",
    "To shopping cart": "Naar winkelmandje",
    "Often bought together with:": "Vaak samengekocht met:",
    "Added to cart": "Toegevoegd aan je winkelmandje",
    "Search": "Zoeken",
    "Postcode or city": "Postcode of stad",
    "Remove from my favorites": "Verwijder van mijn favorieten",
    "Check out deal": "Bekijk deal",
    "Product has been added to your shopping cart": "Product is toegevoegd aan je winkelmandje",
    "{productName} has been added to your shopping cart": "{productName} is toegevoegd aan je winkelmandje",
    "Sort by:": "Sorteren op",
    "Sort:": "Sorteren",
    "Per page:": "Per pagina:",
    "Popularity": "Populariteit",
    "Newest": "Nieuwste",
    "Name (A-Z)": "Naam (A-Z)",
    "Name (Z-A)": "Naam (Z-A)",
    "Price (Ascending)": "Prijs (Oplopend)",
    "Price (Descending)": "Prijs (Aflopend)",
    "Durability score (Ascending)": "Duurzaamheidsscore (Oplopend)",
    "Durability score (Descending)": "Duurzaamheidsscore (Aflopend)",
    "Price": "Prijs",
    "login": "inloggen",
    "Rating": "Review",
    "Ratings": "Reviews",
    "Your name": "Je naam",
    "Your ordernumber": "Je ordernummer",
    "Title of your review": "Titel van je beoordeling",
    "What do you think of {product}": "Wat vind je van {product}",
    "Submit your review": "Plaats je beoordeling",
    "Select your address": "Selecteer jouw adres",
    "Subcategories": "Kies een subcategorie",
    "Show all results for {query}": "Toon alle resultaten voor {query}",
    "Hit enter or try another search.": "Druk op enter of probeer een andere zoekterm.",
    "Shop now": "Shop nu",
    "Searching..": "Zoeken..",
    "Invalid address. Are you sure it is correct?": "Ongeldig adres. Weet je zeker dat het klopt?",
    "Click here to enter the address manually.": "Klik hier om het adres handmatig in te voeren.",
    "Start new shopping cart": "Winkelmandje legen",
    "Sir": "Meneer",
    "Madam": "Mevrouw",
    "Date of birth": "Geboortedatum",
    "Please wait, we are loading stock information...": "Even geduld, we zijn voorraadinformatie aan het laden...",
    "We can't find a Xenos store with stock, try another city or zip code.": "We kunnen geen Xenos winkel met voorraad vinden, probeer een andere stad of postcode.",
    "{name} has been added to my favorites.": "{name} is toegevoegd aan mijn favorieten.",
    "{name} has been removed from my favorites.": "{name} is verwijderd uit mijn favorieten.",
    "Show less": "Toon minder",
    "Search store": "Zoek winkel",
    "Route to this Xenos store": "Route naar deze Xenos-winkel",
    "Your starting point (city or zip code)": "Je vertrekpunt (plaats of postcode)",
    "Postcode / city": "Postcode / stad",
    "Show route": "Toon route",
    "Directions from {origin} to {storeName}": "Routebeschrijving van {origin} naar {storeName}",
    "Unable to place review": "Kan review niet plaatsen",
    "Sign me up": "Schrijf me in",
    "Only available in our stores.": "Alleen in onze winkels.",
    "Online soon.": "Binnenkort online.",
    "Reset selected filters": "Geselecteerde filters resetten",
    "Thank you! Confirm your subscription in your email.": "Bedankt! Bevestig je inschrijving in de e-mail.",
    "Subscription failed, please try again later.": "Mislukt, probeer het later opnieuw.",
    "Enter a plural of {minQtyStepSize}": "Vul een meervoud van {minQtyStepSize} in",
    "The requested amount is currently not available": "Het ingevulde aantal is momenteel niet in voorraad",
    "This product can only be ordered per {minimumQtyStepSize} pieces": "Dit product is alleen per {minimumQtyStepSize} stuks te bestellen",
    "We use this to inform you about the delivery period or if you have questions about your order.": "Dit gebruiken we om je te informeren over het tijdvak van leveren of bij vragen over je bestelling.",
    "We use this to be able to send a track & trace link to follow the delivery of your order.": "Dit gebruiken we om een track & trace link te kunnen sturen om de levering van je bestelling te volgen.",
    "Products from a previous shopping session have been added to your shopping cart. <a href='/checkout/cart'>View products.</a>": "Er zijn producten uit een eerdere shopsessie toegevoegd aan je winkelmandje. <a href='/checkout/cart'>Bekijk producten.</a>",
    "Thank you for your contribution. Your review has been received and will be checked for language use. For questions about the product or availability, please contact our {url}.": "Bedankt voor je review. Nog even geduld, we lopen jouw review na op taalgebruik. Voor informatie met betrekking tot het product of de beschikbaarheid, kun je contact met ons opnemen via de {url}.",
    "customer service": "klantenservice",
    "Something went wrong, the payment could not be completed. Please try again or choose a different payment method.": "Er ging iets mis, de betaling kon niet worden afgerond. Probeer het opnieuw of kies een andere betaalmethode.",
    "Durabilityscore": "Duurzaamheidsscore",
    "manufacturing_process": "Productieproces",
    "product_materials": "Product materialen",
    "organization_data": "Organisatie informatie",
    "Something went wrong with signing up.": "Er ging iets mis bij inschrijven",
    "Something went wrong while creating your account, please try again later.": "Er ging iets mis bij het aanmaken van je account. Probeer het later nog eens.",
    "Your account has been created, log in to track your order.": "Het account is aangemaakt, je kunt nu inloggen om je bestelling te volgen.",
    "You will be the first to receive an email when this product is back in stock.": "Je krijgt een mailtje zodra dit artikel weer op voorraad is.",
    "Search our webshop:": "Zoek op onze webshop:",
    "The account sign-in was incorrect or your account is disabled temporarily. Please wait and try again later.": "De combinatie van dit e-mailadres en het wachtwoord herkennen we niet. Probeer het nog eens of kies 'wachtwoord vergeten' en stel een nieuw wachtwoord in.",
    "Show {totalItems} products": "Toon {totalItems} artikelen",
    "Filter in {totalItems} products": "Filter in {totalItems} artikelen",
    "FSC Certified": "FSC gecertificeerd",
    "YES": "Ja",
    "NO": "Nee",
    "No products were found in this category.": "Er zijn geen producten gevonden in deze categorie.",
    "SKU": "Artikelnummer",
    "Information is currently being collected, processed and validated.": "Informatie wordt verzameld, verwerkt en gevalideerd.",
    "The coupon code isn't valid. Verify the code and try again.": "De kortingscode is niet geldig. Probeer het opnieuw.",
    "You have {hours} hours": "Je hebt nog {hours} uur",
    "and {minutes} minutes left!": "en 0 minuten! | en 1 minuut! | en {minutes} minuten!",
    "Provided order number does not exist.": "Er is geen order gevonden met het opgegeven ordernummer.",
    "Unable to place order, please try again later.": "Kan bestelling niet plaatsen, probeer het later opnieuw.",
    "ESSENTIAL ITEMS": "KASSAKOOPJES",
    "Read more": "Lees meer",
    "Search results": "Zoekresultaten",
    "Order received": "Bestelling ontvangen",
    "Rather ship to Belgium? Click here to switch to the Belgian webshop": "Verzenden naar Belgi\u00eb? Ga naar de BE webshop.",
    "Sponsored": "Gesponsord",
    "Do not forget to enter your number of stars": "Vergeet niet je aantal sterren in te vullen",
    "Rather ship to The netherlands? Click here to switch to the Dutch webshop": "Verzenden naar Nederland? Ga naar de NL webshop.",
    "Select a timeframe": "Selecteer een tijdvak.",
    "Your order will be expected on:": "Verwachte bezorgdatum:",
    "Please wait, we are loading the timeframes": "Nog even geduld, we laden de bezorgtijden in.",
    "Select a delivery date": "Selecteer een bezorgdatum",
    "As soon as possible": "1-2 werkdagen",
    "January": "Januari",
    "February": "Februari",
    "March": "Maart",
    "April": "April",
    "May": "Mei",
    "June": "Juni",
    "July": "Juli",
    "August": "Augustus",
    "September": "September",
    "October": "Oktober",
    "November": "November",
    "December": "December",
    "Here you will find your favorite items that you have collected while shopping to keep.": "Hier vind je jouw favoriete artikelen die je tijdens het shoppen hebt verzameld om te bewaren.",
    "View": "Bekijken",
    "wireui:{messages}.selectTime": "Kies een tijd",
    "Subscribe to the newsletter": "Meld je aan voor de nieuwsbrief",
    "folders:{meta}.title": "Folderacties \u2013 shop de aanbiedingen uit de folder",
    "folders:{meta}.description.nl_BE": "Bekijk de nieuwe Xenos folder voor de beste aanbiedingen en scoor de leukste producten. Shop alle acties direct online.",
    "folders:{meta}.description.nl_NL": "Bekijk de nieuwe Xenos folder voor de beste aanbiedingen en scoor de leukste producten. Shop alle acties direct online of in een van de Xenos winkels.",
    "Shop By Looks": "Shop de stijl",
    "Products Found": "Producten gevonden",
    "Easily shop all the items you see in the image.": "Shop gemakkelijk alle artikelen die je in de afbeelding ziet.",
    "Zoom in": "Inzoomen",
    "To write a review, a valid email address is required for verification.": "Voor het schrijven van een review is een geldig e-mail adres nodig ter verificatie.",
    "Close": "Sluiten",
    "Our review policy": "Ons reviewbeleid",
    "Back to {category}": "Terug naar {category}",
    "View all products in": "Bekijk alle producten in",
    "No score yet available": "(Nog) geen score bekend",
    "Tell us what you think about this product and share your experience with other customers. If you have specific questions about the product, please contact our": "Vertel ons wat je vindt van dit product en deel je ervaring met andere klanten. Heb je specifieke vragen over het product, neem dan contact op met onze",
    "How many stars do you give the {name}": "Hoeveel sterren geef je de {name}",
    "Color": "Kleur",
    "{number} of {total} reviews": "{number} van {total} reviews",
    "Show more reviews": "Toon meer reviews",
    "Based on {productRating} ratings": "Op basis van {productRating} reviews",
    "Found products": "Gevonden producten",
    "Filters": "Filters",
    "Filter": "Filter",
    "Other filters": "Overige filters",
    "Read less": "Lees minder",
    "Show filtered products": "Toon gefilterde producten",
    "Active filters": "Actieve filters",
    "Per page": "Per pagina"
}
import {appSessionStorage} from "../../../../shared/services";

const initialState = () => {
  return {
    hasError: false,
    pickupPoints: [],
    selectedPickupPoint: appSessionStorage.getItem('selected-postnl-pickup-point', null),
    loadingPickupPoints: true,
    customer: appSessionStorage.getItem('postnl-pickup-point-customer-location', {
      zipcode: null,
      lat: 0,
      long: 0,
    }),
  };
}

const getters = {
  hasError: (state) => state.hasError,
  customer: (state) => state.customer,
  pickupPoints: (state) => state.pickupPoints,
  selectedPickupPoint: (state) => state.selectedPickupPoint,
  loadingPickupPoints: (state) => state.loadingPickupPoints,
};

const mutations = {
  SET_LOADING_PICKUP_POINTS(state, status) {
    state.loadingPickupPoints = status;
  },
  SET_PICKUP_POINTS(state, pickupPoints) {
    state.pickupPoints = pickupPoints;
  },
  SET_CUSTOMER_DATA(state, customer) {
    appSessionStorage.setItem('postnl-pickup-point-customer-location', customer);
    state.customer = customer;
  },
  SET_NO_PICKUP_POINTS(state, hasError) {
    state.hasError = hasError;
  },
  SET_PICKUP_POINT(state, pickupPoint) {
    if (null === pickupPoint) {
      appSessionStorage.removeItem('selected-postnl-pickup-point');
      state.selectedPickupPoint = null;
      return;
    }

    appSessionStorage.setItem('selected-postnl-pickup-point', pickupPoint);
    state.selectedPickupPoint = pickupPoint;
  },
};

const actions = {
  async collectPickupPoints({rootState, commit}) {
    commit('SET_LOADING_PICKUP_POINTS', true);
    commit('SET_NO_PICKUP_POINTS', false);
    try {
      const {data} = await this.$solarClient.get(`/api/checkout/pickup-points/nearest/${rootState['CheckoutAddress']['shippingAddress']['postcode']}`);

      await commit('SET_PICKUP_POINTS', data);

      if (data.hasOwnProperty('status') && data['status'] === 'error') {
        await commit('SET_NO_PICKUP_POINTS', true);
      }
    } catch (err) {
      await commit('SET_NO_PICKUP_POINTS', true);
      await commit('SET_PICKUP_POINTS', []);
    } finally {
      commit('SET_LOADING_PICKUP_POINTS', false);
    }
  },
  async collectPickupPointsForZipcode({state, commit}, zipcode) {
    commit('SET_LOADING_PICKUP_POINTS', true);
    commit('SET_NO_PICKUP_POINTS', false);
    try {
      const {data} = await this.$solarClient.get(`/api/checkout/pickup-points/nearest/${zipcode}`);

      await commit('SET_PICKUP_POINTS', data);

      if (data.hasOwnProperty('status') && data['status'] === 'error') {
        await commit('SET_NO_PICKUP_POINTS', true);
      }
    } catch (err) {
      await commit('SET_NO_PICKUP_POINTS', true);
      await commit('SET_PICKUP_POINTS', []);
    } finally {
      commit('SET_LOADING_PICKUP_POINTS', false);
    }
  },
  setPickupPoint({state, commit}, pickupPoint) {
    commit('SET_PICKUP_POINT', pickupPoint);
    commit('CheckoutGlobal/ADD_EXTENSION_ATTRIBUTE', {
      postnl_pickup_point: pickupPoint['LocationCode'],
    }, {root: true});

    commit('CheckoutPayment/ADD_SHIPPING_OPTIONS', {
      postnl_pickup_point: pickupPoint
    }, {root: true});
  },
  removePickupPoints({commit}) {
    commit('SET_PICKUP_POINT', null);
    appSessionStorage.removeItem('selected-postnl-pickup-point');

    commit('CheckoutPayment/CLEAR_SHIPPING_OPTIONS', undefined, {root: true});
  },
  resetPickupPoints({commit, dispatch}) {
    commit('SET_PICKUP_POINTS', []);
    commit('SET_PICKUP_POINT', null);
    commit('SET_LOADING_PICKUP_POINTS', true);
    commit('CheckoutPayment/CLEAR_SHIPPING_OPTIONS', undefined, {root: true});
    dispatch('collectPickupPoints');
  },

  async lookupAddress({commit, rootState}, zipcode) {
    commit('SET_LOADING_PICKUP_POINTS', true);
    const {data} = await this.$solarClient.get(`/api/postcode/lookup/${zipcode}`);

    await commit('SET_CUSTOMER_DATA', {
      zipcode: data['zipcode'],
      lat: parseFloat(data['latitude']),
      lng: parseFloat(data['longitude']),
    });

    return true;
  }
}

const state = initialState();

const CheckoutPostNLPickupPoints = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

export default CheckoutPostNLPickupPoints;

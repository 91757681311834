import {appLocalStorage} from "../../../shared/services";

const initialState = () => {
  return {};
}

const getters = {};

const mutations = {};

const actions = {
  collectCustomer() {
    this.$solarClient.get('api/customer/collect')
  },
  collectCustomerAddresses() {
    this.$solarClient.get('api/customer/address/collect')
  }
}

const state = initialState();

const CustomerAddress = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

export default CustomerAddress;

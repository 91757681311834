window.addEventListener('load', () => {
  const footerLinksElements = document.getElementsByClassName('footer__block__links');
  if (footerLinksElements && footerLinksElements.length > 0) {
    const clickableElements = footerLinksElements[0].getElementsByTagName('p');
    const linkElements = footerLinksElements[0].getElementsByTagName('ul');

    if (clickableElements.length) {
      Array.from(clickableElements).forEach(el => {
        el.firstChild?.addEventListener('click', () => {
          if (el.classList.contains('--active')) {
            el.classList.remove('--active');
            (el.nextElementSibling as HTMLElement).style.display = 'none';
            return;
          }

          Array.from(clickableElements).forEach(allElement => {
            allElement.classList.remove('--active');
          });
          Array.from(linkElements).forEach(ulElement => {
            ulElement.style.display = 'none';
          });

          el.classList.add('--active');
          (el.nextElementSibling as HTMLElement).style.display = 'block';
        });
      });
    }
  }
});

Array.from(document.getElementsByClassName('is-drilldown-submenu-parent')).forEach(firstEl => {
  Array.from(firstEl.getElementsByClassName('nested')).forEach(nestedEl => {
    if (nestedEl.getElementsByClassName('nested').length) {
      Array.from(nestedEl.getElementsByClassName('nested')).forEach(thirdLevel => {
        thirdLevel.parentElement.classList.add('is-drilldown-submenu-parent');
        let backLi = document.createElement('li');
        backLi.innerHTML = '<button>Terug</button>';
        backLi.classList.add('js-drilldown-back');
        thirdLevel.insertBefore(backLi, thirdLevel.children[0]);
      });
    }
  });
});

import SimpleLightbox from 'simplelightbox';
window.addEventListener('load', () => {
  let gallery = new SimpleLightbox('.lightbox__container div:not(.video-container)', {
    maxZoom: 5,
    loop: false,
    overlayOpacity: 0.6,
    navText: ['', ''],
    closeText: '',
    showCounter: false,
    animationSlide: false,
    animationSpeed: 50,
    fadeSpeed: 100,
    sourceAttr: 'data-zoom'
  });

  const zoomButton = document.querySelector('.zoom__link') as HTMLButtonElement;
  if (zoomButton) {
    zoomButton.addEventListener('click', function (event) {
      event.preventDefault();

      let activeSlideIndex = 0;
      
      const mediaGallery = document.getElementById('mediaGallery') as HTMLElement;
      if (mediaGallery && 'blazeSlider' in mediaGallery) {
        const blazeSlider = (mediaGallery as any).blazeSlider;
        activeSlideIndex = blazeSlider?.stateIndex || 0;
      }

      const galleryItems = document.querySelectorAll('.lightbox__container div:not(.video-container)');
      gallery.open(galleryItems[activeSlideIndex] || undefined);
    });
  }

  gallery.on('changed.simplelightbox', function (e) {
    const mediaGallery = document.getElementById('mediaGallery') as HTMLElement;
    const thumbnailGallery = document.getElementById('thumbnailGallery') as HTMLElement;

    const blazeSlider = mediaGallery ? (mediaGallery as any).blazeSlider : null;
    const blazeSliderThumbnail = thumbnailGallery ? (thumbnailGallery as any).blazeSlider : null;

    const index = e.target.getAttribute('data-slider-index');

    if (blazeSlider && blazeSlider.slideTo) {
      blazeSlider.slideTo(index);
    }

    if (blazeSliderThumbnail && blazeSliderThumbnail.slideTo) {
      blazeSliderThumbnail.slideTo(index);
    }
  });
});

// CMS Accordion
// const accordionEls = document.getElementsByClassName('accordion');
// if (accordionEls.length) {
//   for (let accordionEl of accordionEls) {
//     const ulEls = accordionEl.getElementsByTagName('ul');
//     for (let el of ulEls) {
//       for (let liItem of el.getElementsByTagName('li')) {
//         if (liItem.classList.contains('title')) {
//           liItem.insertAdjacentHTML('beforeend', '<i class="fa fa-angle-down"></i>');
//         } else {
//           liItem.style.display = 'none';
//         }
//       }
//     }
//
//     for (let clickEl of accordionEl.querySelectorAll('li.title')) {
//       clickEl.addEventListener('click', el => {
//         const targetEl = (el.target as HTMLElement);
//         const icon = targetEl.tagName === 'LI' ? targetEl.getElementsByTagName('i')[0] : targetEl;
//         if (icon.classList.contains('fa-angle-down')) {
//           icon.classList.remove('fa-angle-down');
//           icon.classList.add('fa-angle-up');
//           // for (let liItem of clickEl.parentElement.getElementsByTagName('li')) {
//           //   if (!liItem.classList.contains('title')) {
//           //     liItem.style.display = 'block';
//           //   }
//           // }
//         } else {
//           icon.classList.add('fa-angle-down');
//           icon.classList.remove('fa-angle-up');
//           // for (let liItem of clickEl.parentElement.getElementsByTagName('li')) {
//           //   if (!liItem.classList.contains('title')) {
//           //     liItem.style.display = 'none';
//           //   }
//           // }
//         }
//       });
//     }
//   }
// }

import {appSessionStorage} from "../../../../shared/services";

const initialState = () => {
  return {
    hasError: false,
    timeframes: [],
    timeframesEnabled: false,
    selectedOption: appSessionStorage.getItem('selected-postnl-timeframe-option', {}),
    selectedTimeframe: appSessionStorage.getItem('selected-postnl-timeframe', {}),
    loadingTimeframes: false,
  };
}

const getters = {
  hasError: (state) => state.hasError,
  timeframes: (state) => state.timeframes,
  selectedOption: (state) => state.selectedOption,
  timeframesEnabled: (state) => state.timeframesEnabled,
  selectedTimeframe: (state) => state.selectedTimeframe,
  loadingTimeframes: (state) => state.loadingTimeframes,
};

const mutations = {
  SET_TIMEFRAME(state, timeframe) {
    appSessionStorage.setItem('selected-postnl-timeframe', timeframe);
    state.selectedTimeframe = timeframe;
  },
  SET_SELECTED_OPTION(state, selectedOption) {
    appSessionStorage.setItem('selected-postnl-timeframe-option', selectedOption);
    state.selectedOption = selectedOption;
  },
  SET_TIMEFRAMES(state, data) {
    state.timeframes = data;
  },
  SET_NO_TIMEFRAMES(state, hasError) {
    state.hasError = hasError;
  },
  SET_LOADING_TIMEFRAMES(state, status) {
    state.loadingTimeframes = status;
  },
  SET_TIMEFRAMES_ENABLED(state, status) {
    state.timeframesEnabled = status;
  },
};

const actions = {
  async timeframesEnabled({rootState, state, commit}) {
    try {
      const {data} = await this.$solarClient.get(`/api/checkout/timeframes/enabled`);

      await commit('SET_TIMEFRAMES_ENABLED', !!data);
    } catch (err) {
      await commit('SET_TIMEFRAMES_ENABLED', false);
    }
  },
  async collectTimeFrames({rootState, state, commit, dispatch}) {
    await dispatch('timeframesEnabled');
    commit('SET_LOADING_TIMEFRAMES', true);
    commit('SET_NO_TIMEFRAMES', false);

    try {
      const {data} = await this.$solarClient.get(`/api/checkout/timeframes/collect/${rootState['CheckoutAddress']['shippingAddress']['country_id']}/${rootState['CheckoutAddress']['shippingAddress']['postcode']}`);

      await commit('SET_TIMEFRAMES', data);

      if (data.hasOwnProperty('status') && data['status'] === 'error') {
        await commit('SET_NO_TIMEFRAMES', true);
      }
    } catch (err) {
      await commit('SET_NO_TIMEFRAMES', true);
      await commit('SET_TIMEFRAMES', []);
    } finally {
      commit('SET_LOADING_TIMEFRAMES', false);
    }
  },
  setTimeframe({state, commit, dispatch}, timeframe) {
    commit('SET_TIMEFRAME', timeframe);

    if ('undefined' !== typeof timeframe['Timeframes'] && timeframe['Timeframes']['TimeframeTimeFrame'].hasOwnProperty('From')) {
      dispatch('setSelectedOption', timeframe['Timeframes']['TimeframeTimeFrame'])
    } else {
      dispatch('setSelectedOption', null)
    }

    commit('CheckoutPayment/ADD_SHIPPING_OPTIONS', {
      timeframe: timeframe
    }, {root: true});
  },
  setSelectedOption({state, commit}, selectedOption) {
    commit('SET_SELECTED_OPTION', selectedOption);

    commit('CheckoutPayment/ADD_SHIPPING_OPTIONS', {
      selectedOption: selectedOption
    }, {root: true});
  },
  removeTimeframes({commit}) {
    commit('SET_TIMEFRAME', null);
    appSessionStorage.removeItem('selected-postnl-timeframe');
    appSessionStorage.removeItem('selected-postnl-timeframe-options');

    commit('CheckoutPayment/CLEAR_SHIPPING_OPTIONS', undefined, {root: true});
  },
}

const state = initialState(
);

const CheckoutPostNLTimeframes = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

export default CheckoutPostNLTimeframes;

import {queryList} from "../../../../shared/helpers";

const initialState = () => {
  return {
    searchedQuery: '',
    categoryId: null,
    apiResponse: null,
    apiResponsePending: false,
    pageQueries: {}
  }
};

const state = initialState();

const mutations = {
  SET_CATEGORY_ID(state, categoryId) {
    state.categoryId = categoryId;
  },
  SET_SEARCHED_QUERY(state, searchedQuery) {
    state.searchedQuery = searchedQuery;
  },
  SET_API_RESPONSE_PENDING(state, status) {
    state.apiResponsePending = status;
  },
  SET_API_RESPONSE(state, response) {
    state.apiResponse = response;
  }
};

const actions = {
  setCategoryId({commit}, categoryId) {
    commit('SET_CATEGORY_ID', categoryId);
  },
  setSearchedQuery({commit}, searchedQuery) {
    commit('SET_SEARCHED_QUERY', searchedQuery);
  },
  setApiResponse({commit}, response) {
    commit('SET_API_RESPONSE', response);
  },
  updatePage({dispatch}, page) {
    dispatch('fetchCatalog', {
      pageQueries: Object.assign(queryList, {page: page})
    });
  },
  inCategorySearch({dispatch}, query) {
    dispatch('fetchCatalog', {
      pageQueries: Object.assign(queryList, {query: query})
    });
  },
  updatePageSize({dispatch}, pageSize) {
    dispatch('fetchCatalog', {
      pageQueries: Object.assign(queryList, {page_size: pageSize, page: 1})
    });
  },
  updatePriceFilter({dispatch}, payload) {
    if (payload['facet_code']) {
      queryList[payload['facet_code']] = `${payload.minPrice}-${payload.maxPrice}`;
    } else {
      Object.assign(queryList, {
        minPrice: payload.minPrice,
        maxPrice: payload.maxPrice,
      });
    }

    Object.assign(queryList, {
      page: 1
    });

    dispatch('fetchCatalog', {
      pageQueries: queryList
    });
  },
  resetPriceFilter({dispatch}, facetCode) {
    dispatch('fetchCatalog', {
      removePrice: facetCode
    });
  },
  updatePageSort({dispatch}, sort) {
    let sortString = '';
    Object.entries(sort || {}).forEach(([key, value]) => {
      sortString = `${key} ${value}`
    });

    dispatch('fetchCatalog', {
      pageQueries: Object.assign(queryList, {sort: sortString, page: 1})
    });
  },
  filterAction({dispatch, getters}, payload) {
    dispatch('fetchCatalog', {
      filters: getters.filterList.map(filter => {
        if (filter['code'] === payload.filterCode) {
          filter['options'].forEach(option => {
            if (option['label'] === payload.optionLabel) {
              switch (payload.actionType) {
                case 'toggle':
                  option['active'] = ! option['active'];
                  break;
                case 'activate':
                  option['active'] = true;
                  break;
                case 'deactivate':
                  option['active'] = false;
                  break;
              }
            }
          });
        }
        return filter;
      }),
      pageQueries: {
        page: 1
      }
    });
  },
  toggleFilter({dispatch}, payload) {
    dispatch('filterAction', {
      actionType: 'toggle',
      filterCode: payload.filter['code'],
      optionLabel: payload.option['label'],
    });
  },
  activateFilter({dispatch}, payload) {
    dispatch('filterAction', {
      actionType: 'activate',
      filterCode: payload.filter['code'],
      optionLabel: payload.option['label'],
    });
  },
  deactivateFilter({dispatch}, payload) {
    dispatch('filterAction', {
      actionType: 'deactivate',
      filterCode: payload.filter['code'],
      optionLabel: payload.option['label'],
    });
  },
  singleValueFilter({dispatch, getters}, payload) {
    dispatch('fetchCatalog', {
      filters: getters.filterList.map(filter => {
        if (filter['code'] === payload.filter['code']) {
          filter.options.forEach(o => {
            o['active'] = o['label'] === payload.option['label']
          });
        }
        return filter;
      })
    });
  },
  fetchCatalog({state, commit}, payload) {
    commit('SET_API_RESPONSE_PENDING', true);

    const categoryId = payload['categoryId'] || state.categoryId;
    const filters = payload.filters || (state.apiResponse.filters || []);

    let params = {};
    let queryParams = Object.assign(state.pageQueries, (payload.pageQueries || {}));

    if(payload.hasOwnProperty('removePrice')) {
      delete queryParams['minPrice'];
      delete queryParams['maxPrice'];
      delete queryParams[payload.removePrice];
    }

    // if ('undefined' === typeof queryParams['page_size']) {
    // queryParams['page_size'] = getters.pageSize(state);
    // }

    if ('undefined' === typeof queryParams['query'] && state.searchedQuery !== '') {
      queryParams['query'] = state.searchedQuery;
    }

    if ('undefined' === typeof queryParams['query'] && state.searchedQuery !== '') {
      queryParams['query'] = state.searchedQuery;
    }

    if (getters.priceFilters(state).length) {
      if ('undefined' === typeof queryParams['minPrice'] && getters.priceFilters(state)[0]['MinimumValue'] > 0) {
        queryParams['minPrice'] = getters.priceFilters(state)[0]['MinimumValue'];
      }

      if ('undefined' === typeof queryParams['maxPrice'] && getters.priceFilters(state)[0]['MaximumValue'] > 0) {
        queryParams['maxPrice'] = getters.priceFilters(state)[0]['MaximumValue'];
      }
    }

    if ('undefined' === typeof queryParams['sort'] && null !== getters.currentSort(state)) {
      let sortString = '';
      Object.entries(getters.currentSort(state) || {}).forEach(([key, value]) => {
        sortString = `${key} ${value}`
      });

      queryParams['sort'] = sortString;
    }

    if (filters.length) {
      let activeFilters = filters.filter(item => {
        if (item.type !== 'attribute') {
          return false;
        }

        let activeOptions = item.options.filter(option => {
          return option.active;
        });

        return activeOptions.length;
      });

      let filterSegments = {};
      activeFilters.forEach(filter => {
        filter.options.forEach(option => {
          if (option.active) {
            if ( ! filterSegments.hasOwnProperty(filter['facet_code'])) {
              filterSegments[filter['facet_code']] = [];
            }

            filterSegments[filter['facet_code']].push(
              `${option.label}`
            );
          }
        });
      });
      params = Object.assign(filterSegments, params);
    }

    const apiUrl = state.searchedQuery !== '' ? `/api/catalog/search/product/list` : `/api/catalog/category/${categoryId}/product/list`;

    this.$solarClient.get(
      apiUrl, {
        params: Object.assign(params, queryParams)
      }).then(({data}) => {
      commit('SET_API_RESPONSE', data);

      const urlFilters = data.filters.filter(
        filter => filter['is_active']
      ).map(filter => {
        return filter['options'].filter(
          filterOption => filterOption['active']
        ).map(filterOption => {
          return (filter['has_duplicate_values'] ? `${filter['url_key']}-` : '') + `${filterOption['label']}`.replace(/\s/g, '-');
        }).join('_');
      });

      let urlSegments = window.location.pathname.split('/').filter(segment => segment);
      const urlHasFilterSegment = urlSegments.filter(segment => segment[0] === '_').length > 0;
      if (urlHasFilterSegment && ! urlFilters.length) {
        urlSegments = urlSegments.filter(segment => segment[0] !== '_');
      }

      if (urlFilters.length) {
        const encodedActiveFilterValues = urlFilters.map(v => encodeURIComponent(v));

        if (urlHasFilterSegment) {
          urlSegments = urlSegments.map(segment => {
            return segment[0] === '_' && urlSegments.length ? `_${encodedActiveFilterValues.join('_')}` : segment;
          });
        } else {
          urlSegments.push(`_${encodedActiveFilterValues.join('_')}`);
        }
      }

      let queryString = '';
      if (Object.keys(queryParams).length) {
        Object.entries(queryParams).forEach(([key, value]) => {
          queryString += `&${key}=${value}`;
        });

        queryString = `?${queryString.substr(1)}`;
      }

      window.history.pushState({
          categoryId: categoryId,
          catalogData: data
        },
        window.document.querySelector('title').value,
        window.location.origin + '/' + urlSegments.join('/') + queryString
      );
    }).finally(() => {
      commit('SET_API_RESPONSE_PENDING', false);
    });
  }
};

const getters = {
  isLoading: state => {
    return state.apiResponsePending;
  },
  currentPage: state => {
    return state.apiResponse ? state.apiResponse['current_page'] : 1;
  },
  currentSort: state => {
    return state.apiResponse ? state.apiResponse['sorting'] : null;
  },
  currentSortNew: state => {
    if (null === state.apiResponse) {
      return null;
    }

    const activeOption = state.apiResponse['sorting_options'].filter(s => s['is_active'])[0] || null;

    return activeOption ? (activeOption['key'] || null) : null;
  },
  totalPages: state => {
    return state.apiResponse ? state.apiResponse['last_page'] : 1;
  },
  pageSize: state => {
    return state.apiResponse ? state.apiResponse['page_size'] : 24;
  },
  productList: state => {
    return state.apiResponse ? (state.apiResponse.hasOwnProperty('items') ? state.apiResponse['items'] : []) : [];
  },
  filterList: state => {
    return state.apiResponse ? (state.apiResponse.hasOwnProperty('filters') ? state.apiResponse['filters'] : []) : [];
  },
  priceFilters: state => {
    if (state.apiResponse && state.apiResponse.hasOwnProperty('filters')) {
      return state.apiResponse.filters.filter(o => {
        return o.type === 'price'
      });
    }
  },
  activeFilters: (state, getters) => {
    return getters.filterList.filter(filter => {
      return filter['type'] !== 'attribute' ? false : filter['is_active'];
    });
  },
  activeCrawlableFilters: (state, getters) => {
    return getters.activeFilters.filter(filter => filter['is_crawlable']);
  },
  totalItemCount: state => {
    return state.apiResponse ? (state.apiResponse.hasOwnProperty('total_items') ? state.apiResponse['total_items'] : 0) : 0;
  }
};

const CatalogCategory = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};

export default CatalogCategory;

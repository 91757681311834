



















import {Component, Prop, Watch, Vue} from 'vue-property-decorator';

@Component({
  name: 'header-wishlist'
})

export default class extends Vue {
  @Prop({default: [], required: false}) private items: Array<number>;
  @Prop() readonly label: string;

  private enableTransition: boolean = false;

  @Watch('wishlistCount')
  private handleWishlistCount(): void {
    this.enableTransition = true;
    setTimeout(() => {
      this.enableTransition = false;
    }, 300);
  }

  private created(): void {
    this.$store.dispatch('CustomerWishlist/setWishlistCount', this.items.length);
    this.$store.dispatch('CustomerWishlist/setWishlistProducts', this.items);
  }

  private get wishlistCount(): number {
    return this.$store.getters['CustomerWishlist/wishlistCount'];
  }
}

import {Vue} from 'vue-property-decorator';

const initialState = () => {
  return {
    selectedQuantity: 1,
    quantitySteps: [],
    disableAddToCart: false,

    minQtyStepSize: 1,
    minQtySteps: 10,
    maxQty: 10,
    hasMaxSalesQty: false,

    showInputValidationMessage: false,
    showInputMaxStockValidationMessage: false
  }
};

const mutations = {
  SET_SELECTED_QUANTITY(state, payload) {
    Vue.set(state, 'selectedQuantity', payload);
  },
  SET_MIN_QTY_STEP_SIZE(state, payload) {
    state.minQtyStepSize = payload;
  },
  SET_MIN_QTY_STEPS(state, payload) {
    state.minQtySteps = payload;
  },
  SET_MAX_QTY(state, payload) {
    state.maxQty = payload;
  },
  SET_QUANTITY_STEP(state, payload) {
    state.quantitySteps = payload;
  },
  SET_DISABLE_ADD_TO_CART(state, payload) {
    Vue.set(state, 'disableAddToCart', payload);
  },
  SET_INPUT_VALIDATION_MESSAGE(state, payload) {
    Vue.set(state, 'showInputValidationMessage', payload);
  },
  SET_INPUT_MAX_STOCK_VALIDATION_MESSAGE(state, payload) {
    Vue.set(state, 'showInputMaxStockValidationMessage', payload);
  }
};

const actions = {
  initialize({state, commit}, product) {
    commit('SET_MIN_QTY_STEP_SIZE', parseInt(product['minimum_qty_allowed']['increment'] || 1));
    commit('SET_SELECTED_QUANTITY', parseInt(product['minimum_qty_allowed']['increment'] || 1));
    commit('SET_MIN_QTY_STEPS', parseInt(product['minimum_qty_allowed']['multiplier'] || 10));

    let maxOrderableStock = product['frontend_stock'];
    if (!!product['backorder'] || (product['has_max_sales_qty'] && product['frontend_stock'] > product['has_max_sales_qty'])) {
      maxOrderableStock = product['max_sales_qty'];
    }

    commit('SET_MAX_QTY', maxOrderableStock);

    const qtySelectMaxSteps = (state.minQtyStepSize * state.minQtySteps) > state.maxQty ? Math.floor(state.maxQty / state.minQtyStepSize) : state.minQtySteps;
    commit('SET_QUANTITY_STEP', [...Array(qtySelectMaxSteps)].map((_, i) => state.minQtyStepSize * (i + 1)));
  },
  setQuantity({state, commit}, quantity) {
    commit('SET_SELECTED_QUANTITY', quantity);

    if(quantity > state.maxQty) {
      commit('SET_DISABLE_ADD_TO_CART', true);
      commit('SET_INPUT_MAX_STOCK_VALIDATION_MESSAGE', true);
      return;
    }

    if (quantity % state.minQtyStepSize !== 0 && quantity > 0) {
      commit('SET_DISABLE_ADD_TO_CART', true);
      commit('SET_INPUT_VALIDATION_MESSAGE', true);
      return;
    }

    commit('SET_DISABLE_ADD_TO_CART', false);
    commit('SET_INPUT_VALIDATION_MESSAGE', false);
    commit('SET_INPUT_MAX_STOCK_VALIDATION_MESSAGE', false);
  }
};

const getters = {
  selectedQuantity: state => state.selectedQuantity
};

const ProductQuantity = {
  namespaced: true,
  initialState,
  mutations,
  actions,
  getters,
};

export default ProductQuantity;

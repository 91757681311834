import {appLocalStorage, appSessionStorage} from "../../../shared/services";

const initialState = () => {
  return {
    currentStep: 1,
    showLoginForm: false,
    customerEmail: appLocalStorage.getItem('checkout-customer-email', null),
    extensionAttributes: appSessionStorage.getItem('checkout-extension-attributes', {}),
  };
}

const getters = {
  currentStep: state => state.currentStep,
  customerEmail: state => state.customerEmail,
  getShowLoginForm: state => state.showLoginForm,
  getExtensionAttributes: state => state.extensionAttributes,
};

const mutations = {
  TOGGLE_LOGIN_FORM(state) {
    state.showLoginForm = !state.showLoginForm;
  },
  SET_CURRENT_STEP(state, currentStep) {
    state.currentStep = currentStep;
  },
  SET_CUSTOMER_EMAIL(state, customerEmail) {
    state.customerEmail = customerEmail;
    appLocalStorage.setItem('checkout-customer-email', customerEmail);
  },
  ADD_EXTENSION_ATTRIBUTE(state, extensionAttribute) {
    state.extensionAttributes = {...state.extensionAttributes, ...extensionAttribute};
    appSessionStorage.setItem('checkout-extension-attributes', state.extensionAttributes);
  },
  REMOVE_EXTENSION_ATTRIBUTE(state, extensionAttribute) {
    if (state.extensionAttributes.hasOwnProperty(extensionAttribute)) {
      delete state.extensionAttributes[extensionAttribute];
    }
    appSessionStorage.setItem('checkout-extension-attributes', state.extensionAttributes);
  },
  CLEAR_EXTENSION_ATTRIBUTE(state) {
    state.extensionAttributes = {};
    appSessionStorage.removeItem('checkout-extension-attributes');
  },
};

const actions = {
  async checkCustomerEmail({state, commit, rootState}, customerEmail) {
    try {
      return await this.$solarClient.post(`/api/checkout/customers/isEmailAvailable`,
        {
          customerEmail: customerEmail,
        }
      );
    } catch (e) {
      return false;
    }
  },
  async assignCustomerEmailToQuote({ state, rootState, rootGetters, commit }, customerEmail) {
    try {
      await this.$solarClient.post(`/api/checkout/sellvation-abandoned-cart/assign`, {
        customerData: {
          email: customerEmail,
        }
      });

      return true;
    } catch (e) {
      return false;
    }
  },
  async newsletterSubscribe({state, commit}, newsletterSubscribe) {
    commit('ADD_EXTENSION_ATTRIBUTE', {
      newsletter_subscribe: newsletterSubscribe,
    });
  },
  async gotoStep({state, commit}, step) {
    commit('SET_CURRENT_STEP', step);
  },
  toggleLoginForm({commit}) {
    commit('TOGGLE_LOGIN_FORM');
  }
}

const state = initialState();

const CheckoutGlobal = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

export default CheckoutGlobal;

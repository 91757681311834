import {appLocalStorage} from "../../../shared/services";

const initialState = () => {
  return {
    pickupPoints: [],
    noPickupPoints: false,
    selectedPickupPoint: appLocalStorage.getItem('checkout-shipping-pickup-point', null),
    pickupPointsLoading: true,
  };
}

const getters = {
  pickupPoints: state => state.pickupPoints,
  noPickupPoints: state => state.noPickupPoints,
  selectedPickupPoint: state => state.selectedPickupPoint,
  pickupPointsLoading: state => state.pickupPointsLoading,
};

const mutations = {
  SET_SELECTED_PICKUP_POINT(state, pickupPoint) {
    state.selectedPickupPoint = pickupPoint;
    appLocalStorage.setItem('checkout-shipping-pickup-point', pickupPoint)
    if (null === pickupPoint) {
      appLocalStorage.removeItem('checkout-shipping-pickup-point')
    }
  },
  SET_PICKUP_POINTS(state, pickupPoints) {
    state.pickupPoints = pickupPoints;
  },
  SET_NO_PICKUP_POINTS(state, noPickupPoints) {
    state.noPickupPoints = noPickupPoints;
  },
  SET_LOADING_PICKUP_POINTS(state, pickupPointsLoading) {
    state.pickupPointsLoading = pickupPointsLoading;
  },
};

const actions = {
  setSelectedPickupPoint({commit}, payload) {
    commit('SET_SELECTED_PICKUP_POINT', payload);
    commit('CheckoutGlobal/ADD_EXTENSION_ATTRIBUTE', {
      pickupPoint: payload['id']
    }, {root: true});
  },
  async collectPickupPoints({commit, rootGetters}) {
    try {
      commit('SET_LOADING_PICKUP_POINTS', true);
      commit('SET_NO_PICKUP_POINTS', false);
      const [_billingAddress, _shippingAddress] = rootGetters['CheckoutAddress/getAddressData'];

      const {data} = await this.$solarClient.get(`/api/physical-store/list-by-distance`, {
        params: {
          search: _shippingAddress['postcode'],
          openingHours: 1,
          limit: -1,
          onlyShowInCheckout: 1,
          country: _shippingAddress['country_id']
        }
      });

      await commit('SET_PICKUP_POINTS', data);

      if (data.length > 0) {
        await commit('SET_SELECTED_PICKUP_POINT', data[0]);
      }

      if (data.hasOwnProperty('status') && data['status'] === 'error') {
        await commit('SET_NO_PICKUP_POINTS', true);
      }
    } catch (err) {
      await commit('SET_NO_PICKUP_POINTS', true);
      await commit('SET_PICKUP_POINTS', []);
    } finally {
      commit('SET_LOADING_PICKUP_POINTS', false);
    }
  },
  removePickupPoints({commit}) {
    commit('SET_SELECTED_PICKUP_POINT', null);
    commit('CheckoutGlobal/REMOVE_EXTENSION_ATTRIBUTE', 'pickupPoint', {root: true});
  },
}

const state = initialState();

const CheckoutShippingPickupPoints = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

export default CheckoutShippingPickupPoints;
